// @flow

/**
 * Module dependencies.
 */

import { Container } from 'components/core/layout';
import type { FixedImage, FluidImage } from 'types/image';
import { Image } from 'components/core/image';
import { type StoreUrls } from 'types/store-urls';
import { Type } from 'react-components/typography';
import { color, media } from 'react-components/styles';
import Card from 'components/core/card';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import StoreLinks from 'components/join/store-links';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  coinImage: ?FluidImage,
  description: string,
  splashImage: ?FixedImage,
  storeUrls: StoreUrls,
  title: string
|};

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container).attrs({ as: 'section' })`
  padding-bottom: 10.125rem;
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  padding: 2.5rem;
  position: relative;
  text-align: center;

  ${media.min('sm')`
    padding: 5rem;
  `}
`;

/**
 * `CoinWrapper` styled component.
 */

const CoinWrapper = styled.div`
  display: none;
  pointer-events: none;

  ${media.min('ms')`
    bottom: 0;
    display: initial;
    position: absolute;
    right: 3rem;
    transform: translateY(calc(50% + 0.5rem));
    width: 11rem;
  `}

  ${media.min('md')`
    right: 5rem;
  `}
`;

/**
 * `SplashWrapper` styled component.
 */

const SplashWrapper = styled.div`
  display: none;
  pointer-events: none;

  ${media.min('lg')`
    display: initial;
    left: 0;
    position: absolute;
    top: -180px;
  `}
`;

/**
 * `JoinWaitlist` component.
 */

function JoinWaitlist(props: Props): Node {
  const { coinImage, description, splashImage, storeUrls, title } = props;

  return (
    <StyledContainer>
      <Card>
        <Content>
          <RawHtml
            as={'div'}
            color={color('green500')}
            element={Type.H2}
            marginBottom={'1.5rem'}
          >
            {title}
          </RawHtml>

          <RawHtml
            as={'div'}
            color={color('grey700')}
            element={Type.H5}
            marginBottom={'3.5rem'}
          >
            {description}
          </RawHtml>

          <StoreLinks
            align={'center'}
            urls={storeUrls}
          />

          <CoinWrapper>
            <Image fluid={coinImage} />
          </CoinWrapper>
        </Content>
      </Card>

      <SplashWrapper>
        <Image fixed={splashImage} />
      </SplashWrapper>
    </StyledContainer>
  );
}

/**
 * Export `JoinWaitlist` component.
 */

export default JoinWaitlist;
