// @flow

/**
 * Module dependencies.
 */

import { Button } from 'components/core/buttons';
import { Container } from 'components/core/layout';
import { Type } from 'react-components/typography';
import { color, media } from 'react-components/styles';
import BonusSwitch from './bonus-switch';
import LoyaltyProgramTable, {
  type LoyaltyProgramTableProps
} from './loyalty-program-table';

import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  ...LoyaltyProgramTableProps,
  description?: ?string,
  learnMoreLabel: string,
  learnMoreUrl: string,
  mintBonus: string,
  switchLeftLabel: string,
  switchRightLabel: string,
  title: string
|};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  color: ${color('blue800')};
  padding-bottom: 5rem;
  position: relative;
`;

/**
 * `ButtonWrapper` styled component.
 */

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  white-space: initial;

  ${media.max('xs')`
    width: 100%;
  `}
`;

/**
 * `LoyaltyProgram` component.
 */

function LoyaltyProgram(props: Props): Node {
  const {
    description,
    learnMoreLabel,
    learnMoreUrl,
    mintBonus,
    switchLeftLabel,
    switchRightLabel,
    title,
    ...rest
  } = props;

  return (
    <Section>
      <Container>
        <RawHtml
          as={'div'}
          element={Type.H2}
          marginBottom={'2.5rem'}
          textAlign={'center'}
        >
          {title}
        </RawHtml>

        {description && (
          <RawHtml
            as={'div'}
            color={color('blue700')}
            element={Type.H5}
            marginBottom={'3.5rem'}
            marginHorizontal={'auto'}
            maxWidth={'51.5rem'}
            textAlign={'center'}
          >
            {description}
          </RawHtml>
        )}

        <BonusSwitch
          mintBonus={mintBonus}
          switchLeftLabel={switchLeftLabel}
          switchRightLabel={switchRightLabel}
        />

        <LoyaltyProgramTable {...rest} />

        <ButtonWrapper>
          <StyledButton
            colorTheme={'secondary'}
            href={learnMoreUrl}
            rel={'noopener'}
            target={'_blank'}
            variant={'outline'}
          >
            {learnMoreLabel}
          </StyledButton>
        </ButtonWrapper>
      </Container>
    </Section>
  );
}

/**
 * Export `LoyaltyProgram` component.
 */

export default LoyaltyProgram;
