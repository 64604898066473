// @flow

/**
 * Module dependencies.
 */

import { Icon } from 'react-components/media';
import { type StoreUrls } from 'types/store-urls';
import { switchProp } from 'styled-tools';
import React, { type Node } from 'react';
import appStoreLogoIcon from 'assets/svg/app-store-logo.svg';
import googlePlayLogoIcon from 'assets/svg/google-play-logo.svg';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  align?: 'center' | 'left',
  urls: StoreUrls
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;

  ${switchProp('alignment', {
    center: css`
      justify-content: center;
    `,
    left: css`
      justify-content: flex-start;
    `
  })}
`;

/**
 * `StoreLinks` component.
 */

function StoreLinks(props: Props): Node {
  const { align = 'left', urls } = props;

  return (
    <Wrapper alignment={align}>
      <a
        href={urls.appStore}
        rel={'noopener'}
        target={'_blank'}
      >
        <Icon
          block
          icon={appStoreLogoIcon}
          size={'140px'}
        />
      </a>

      <a
        href={urls.googlePlay}
        rel={'noopener'}
        target={'_blank'}
      >
        <Icon
          block
          icon={googlePlayLogoIcon}
          size={'137px'}
        />
      </a>
    </Wrapper>
  );
}

/**
 * Export `StoreLinks` component.
 */

export default StoreLinks;
