// @flow

/**
 * Module dependencies.
 */

import { Box, Container } from 'components/core/layout';
import { type TemplateProps } from 'types/template';
import { color, media } from 'react-components/styles';
import { getFluidImage } from 'utils/get-fluid-image';
import { graphql, useStaticQuery } from 'gatsby';
import { isEmpty } from 'lodash';
import Faqs from 'components/join/faqs';
import Footer from 'components/footer';
import Header from 'components/join/header';
import HowToStart from 'components/join/how-to-start';
import JoinWaitlist from 'components/join/join-waitlist';
import LoyaltyProgram from 'components/join/loyalty-program';
import Metatags from 'components/metatags';
import PageContainer from 'components/page-container';
import React, { type Node } from 'react';
import Separator from 'components/core/separator';
import SubscriptionForm from 'components/core/subscription-form';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('blue700')};
  color: ${color('white')};
  min-height: 100vh;
`;

/**
 * `LightGradientBackground` styled component.
 */

const LightGradientBackground = styled.div`
  background: linear-gradient(-135deg, rgb(255, 255, 255) 0%, rgb(240, 243, 254) 31%, rgb(255, 255, 255) 100%);
`;

/**
 * `GradientBackground` styled component.
 */

const GradientBackground = styled.div`
  background: linear-gradient(190deg, ${color('blue700')} 0%, ${color('blue800')} 100%);
  padding-top: 9.5rem;

  ${media.min('lg')`
    bottom: 0;
    position: sticky;
  `}
`;

/**
 * Join page query.
 */

const joinPageQuery = graphql`
  query {
    join: seegnoCmsJoinPage {
      content {
        header {
          image {
            file {
              childImageSharp {
                ...ResponsiveImage
              }
            }
          }
        }

        howToStart {
          title
          description
          list {
            title
            description
            id
            image {
              file {
                url: publicURL
              }
            }
          }
        }

        joinWaitlist {
          coinImage {
            file {
              childImageSharp {
                ...ResponsiveImage
              }
            }
          }
        }
      }
    }

    splashImage: file(
      relativePath: { eq: "images/join-splash.png" }
    ) {
      childImageSharp {
        fixed(height: 678) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

/**
 * `Join` page.
 */

function Join({ pageContext }: TemplateProps): Node {
  const { join, splashImage } = useStaticQuery(joinPageQuery);
  const content = pageContext?.content;

  return (
    <PageContainer>
      <Wrapper>
        <Metatags {...pageContext?.metatags} />

        <Box
          backgroundColor={'white'}
          position={'relative'}
          zIndex={1}
        >
          <Header
            {...content?.header}
            description={content.header.description}
            image={getFluidImage(join.content.header)}
            storeUrls={content.storeUrls}
            title={content.header.title}
          />

          <LightGradientBackground>
            <HowToStart
              {...content?.howToStart}
              description={join.content.howToStart.description}
              items={join.content.howToStart.list}
              title={join.content.howToStart.title}
            />

            <LoyaltyProgram {...content.loyaltyProgram} />
          </LightGradientBackground>

          {!isEmpty(content?.faqs?.list) && (
            <Faqs
              description={content.faqs.description}
              items={content.faqs.list}
              title={content.faqs.title}
            />
          )}

          <JoinWaitlist
            coinImage={getFluidImage(join.content.joinWaitlist, 'coinImage')}
            description={content.joinWaitlist.description}
            splashImage={splashImage?.childImageSharp?.fixed}
            storeUrls={content.storeUrls}
            title={content.joinWaitlist.title}
          />
        </Box>

        <GradientBackground>
          <Container>
            <SubscriptionForm />
          </Container>

          <Container>
            <Separator />
          </Container>

          <Footer />
        </GradientBackground>
      </Wrapper>
    </PageContainer>
  );
}

/**
 * Export `Join` page.
 */

export default Join;
