// @flow

/**
 * Module dependencies.
 */

import { color, media } from 'react-components/styles';
import { prop, switchProp } from 'styled-tools';
import React, { type Node } from 'react';
import styled, { css } from 'styled-components';

/**
 * Export `LoyaltyProgramTableProps` type.
 */

export type LoyaltyProgramTableProps = {|
  cellA1: string,
  cellA2: string,
  cellA3: string,
  cellB1: string,
  cellB2: string,
  cellB3: string,
  cellC1: string,
  cellC2: string,
  cellC3: string,
  cellD1: string,
  cellD2: string,
  cellD3: string,
  headerA: string,
  headerB: string,
  headerC: string,
  headerD: string,
  row1Label: string,
  row2Label: string,
  row3Label: string
|};

/**
 * `Table` styled component.
 */

const Table = styled.div.attrs({
  'aria-colcount': 5,
  'aria-role': 'table'
})`
  display: grid;
  grid-template-columns: repeat(4, 25px 1fr 25px);
  grid-template-rows: repeat(7, max-content);
  margin: 3rem 0;

  ${media.min('ms')`
    grid-template-columns: 1fr repeat(4, 25px 1fr 25px);
    grid-template-rows: none;
    margin: 2.5rem auto 5rem;
    max-width: 60rem;
    row-gap: 2.5rem;
  `}
`;

/**
 * `Row` styled component.
 */

const Row = styled.div.attrs({
  'aria-role': 'row'
})`
  display: contents;
`;

/**
 * `BaseCell` styled component.
 */

const BaseCell = styled.div`
  font-size: 18px;
  font-weight: 600;

  ${media.min('ms')`
    font-size: 24px;
  `}
`;

/**
 * Header columns.
 */

const headerColumns = {
  columnA: css`
    grid-column: 1 / 4;

    ${media.min('ms')`
      grid-column: 3;
    `}
  `,
  columnB: css`
    grid-column: 4 / 7;

    ${media.min('ms')`
      grid-column: 6;
    `}
  `,
  columnC: css`
    grid-column: 7 / 10;

    ${media.min('ms')`
      grid-column: 9;
    `}
  `,
  columnD: css`
    grid-column: 10 / 13;

    ${media.min('ms')`
      grid-column: 12;
    `}
  `
};

/**
 * `ColumnHeader` styled component.
 */

const ColumnHeader = styled(BaseCell).attrs({
  'aria-role': 'columnheader'
})`
  color: ${color('blue200')};
  text-align: center;

  ${switchProp('column', headerColumns)}
`;

/**
 * Get row index.
 */

function getRowIndex(rowName: string): number {
  return parseInt(rowName.replace('row', ''), 10) + 1;
}

/**
 * Get desktop row style.
 */

function getDesktopRowStyle({ row }) {
  const rowIndex = getRowIndex(row);

  return css`
    grid-row: ${rowIndex};
    margin-bottom: ${rowIndex < 4 ? '0.875rem' : '0'};
  `;
}

/**
 * `RowHeader` styled component.
 */

const RowHeader = styled.div.attrs({
  'aria-role': 'rowheader'
})`
  border: 1.5px solid ${color('blue100')};
  border-bottom-width: 0;
  border-radius: 12px 12px 0 0;
  color: ${prop('color')};
  font-size: 12px;
  font-weight: 600;
  grid-column: 1 / -1;
  grid-row: ${({ row }) => getRowIndex(row) * 2} / span 2;
  line-height: 38px;
  margin-bottom: 21px;
  margin-top: 1rem;
  padding-bottom: 21px;
  text-align: center;
  text-transform: uppercase;

  ${media.min('ms')`
    ${getDesktopRowStyle}

    border-bottom-width: 1.5px;
    border-radius: 45px 0 0 45px;
    border-right: 0;
    font-size: 16px;
    grid-column: 1 / 3;
    line-height: 28px;
    margin-top: 0;
    padding: 10px 1.5rem 10px 0.75rem;
    text-align: initial;
  `}

  ${media.min('md')`
    padding: 10px 1.5rem 10px 25px;
  `}
`;

/**
 * Gradients.
 */

const gradients = {
  columnA: {
    row1: 'linear-gradient(270deg, rgb(255, 231, 190) 0%, rgb(255, 255, 255) 100%)',
    row2: 'linear-gradient(270deg, rgba(48, 245, 213, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%)',
    row3: 'linear-gradient(90deg, rgba(251, 252, 255, 0.4) 0%, rgba(119, 151, 245, 0.4) 100%)'
  },
  columnB: {
    row1: 'linear-gradient(270deg, rgba(255, 182, 55, 0.4) 0%, rgba(255, 246, 229, 0.4) 100%)',
    row2: 'linear-gradient(270deg, rgba(120, 250, 229, 0.6) 0%, rgba(143, 255, 229, 0.02) 100%)',
    row3: 'linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0%, rgba(164, 184, 243, 0.4) 43%, rgba(104, 143, 255, 0.4) 100%)'
  },
  columnC: {
    row1: 'linear-gradient(270deg, rgba(255, 182, 55, 0.6) 0%, rgba(255, 254, 252, 0.6) 100%)',
    row2: 'linear-gradient(270deg, rgba(48, 245, 213, 0.7) 0%, rgba(236, 255, 251, 0.7) 100%)',
    row3: 'linear-gradient(90deg, rgba(252, 253, 255, 0.6) 0%, rgba(119, 151, 245, 0.6) 100%)'
  },
  columnD: {
    row1: 'linear-gradient(270deg, rgb(255, 182, 55) 0%, rgb(255, 255, 255) 100%)',
    row2: 'linear-gradient(270deg, rgb(48, 245, 213) 0%, rgb(222, 254, 246) 100%)',
    row3: 'linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(119, 151, 245) 80%, rgb(88, 126, 238) 100%)'
  }
};

/**
 * `Cell` styled component.
 */

const Cell = styled(BaseCell).attrs({
  'aria-role': 'cell'
})`
  border: 1.5px solid;
  color: ${color('blue700')};
  grid-row: ${({ row }) => getRowIndex(row) * 2 + 1};
  line-height: 28px;
  padding-bottom: 6px;
  padding-top: 5px;
  text-align: center;

  ${switchProp('column', {
    columnA: css`
      background: ${switchProp('row', gradients.columnA)};
      border-radius: 45px;
      grid-column: 1 / 4;
      z-index: 3;

      ${media.min('ms')`
        grid-column: 2 / 5;
      `}
    `,
    columnB: css`
      background: ${switchProp('row', gradients.columnB)};
      border-left: 0;
      border-radius: 0 45px 45px 0;
      grid-column: 3 / 7;
      padding-left: 25px;
      z-index: 2;

      ${media.min('ms')`
        grid-column: 4 / 8;
      `}
    `,
    columnC: css`
      background: ${switchProp('row', gradients.columnC)};
      border-left: 0;
      border-radius: 0 45px 45px 0;
      grid-column: 6 / 10;
      padding-left: 25px;
      z-index: 1;

      ${media.min('ms')`
        grid-column: 7 / 11;
      `}
    `,
    columnD: css`
      background: ${switchProp('row', gradients.columnD)};
      border-left: 0;
      border-radius: 0 45px 45px 0;
      grid-column: 9 / 13;
      padding-left: 25px;

      ${media.min('ms')`
        grid-column: 10 / 14;
      `}
    `
  })}

  ${switchProp('row', {
    row1: css`
      border-color: ${color('yellow500')};
    `,
    row2: css`
      border-color: ${color('green500')};
    `,
    row3: css`
      border-color: #829ae2;
    `
  })}

  ${media.min('ms')`
    ${getDesktopRowStyle}

    padding-bottom: 10px;
    padding-top: 10px;
  `}
`;

/**
 * `LoyaltyProgramTable` component.
 */

function LoyaltyProgramTable(props: LoyaltyProgramTableProps): Node {
  const {
    cellA1,
    cellA2,
    cellA3,
    cellB1,
    cellB2,
    cellB3,
    cellC1,
    cellC2,
    cellC3,
    cellD1,
    cellD2,
    cellD3,
    headerA,
    headerB,
    headerC,
    headerD,
    row1Label,
    row2Label,
    row3Label
  } = props;

  return (
    <Table>
      <Row aria-colindex={2}>
        <ColumnHeader column={'columnA'}>
          {headerA}
        </ColumnHeader>

        <ColumnHeader column={'columnB'}>
          {headerB}
        </ColumnHeader>

        <ColumnHeader column={'columnC'}>
          {headerC}
        </ColumnHeader>

        <ColumnHeader column={'columnD'}>
          {headerD}
        </ColumnHeader>
      </Row>

      <Row>
        <RowHeader
          color={color('yellow500')}
          row={'row1'}
        >
          {row1Label}
        </RowHeader>

        <Cell
          column={'columnA'}
          row={'row1'}
        >
          {cellA1}
        </Cell>

        <Cell
          column={'columnB'}
          row={'row1'}
        >
          {cellB1}
        </Cell>

        <Cell
          column={'columnC'}
          row={'row1'}
        >
          {cellC1}
        </Cell>

        <Cell
          column={'columnD'}
          row={'row1'}
        >
          {cellD1}
        </Cell>
      </Row>

      <Row>
        <RowHeader
          color={color('green500')}
          row={'row2'}
        >
          {row2Label}
        </RowHeader>

        <Cell
          column={'columnA'}
          row={'row2'}
        >
          {cellA2}
        </Cell>

        <Cell
          column={'columnB'}
          row={'row2'}
        >
          {cellB2}
        </Cell>

        <Cell
          column={'columnC'}
          row={'row2'}
        >
          {cellC2}
        </Cell>

        <Cell
          column={'columnD'}
          row={'row2'}
        >
          {cellD2}
        </Cell>
      </Row>

      <Row>
        <RowHeader
          color={color('blue300')}
          row={'row3'}
        >
          {row3Label}
        </RowHeader>

        <Cell
          column={'columnA'}
          row={'row3'}
        >
          {cellA3}
        </Cell>

        <Cell
          column={'columnB'}
          row={'row3'}
        >
          {cellB3}
        </Cell>

        <Cell
          column={'columnC'}
          row={'row3'}
        >
          {cellC3}
        </Cell>

        <Cell
          column={'columnD'}
          row={'row3'}
        >
          {cellD3}
        </Cell>
      </Row>
    </Table>
  );
}

/**
 * Export `LoyaltyProgramTable` component.
 */

export default LoyaltyProgramTable;
