// @flow

/**
 * Module dependencies.
 */

import styled from 'styled-components';

/**
 * Export `VisuallyHidden` styled component.
 */

export const VisuallyHidden = styled.span`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
