// @flow

/**
 * Module dependencies.
 */

import { Type } from 'react-components/typography';
import { VisuallyHidden } from 'components/core/visually-hidden';
import { color, media } from 'react-components/styles';
import { ifProp, theme } from 'styled-tools';
import RawHtml from 'react-components/raw-html';
import React, { type Node, useState } from 'react';
import styled, { css } from 'styled-components';

/**
 * `BonusSwitchProps` type.
 */

type BonusSwitchProps = {|
  mintBonus: string,
  switchLeftLabel: string,
  switchRightLabel: string
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto max-content auto;
  justify-content: center;
  row-gap: 0.5rem;

  ${media.min('sm')`
    align-items: center;
    display: flex;
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  display: contents;
`;

/**
 * `SwitchBackground` styled component.
 */

const SwitchBackground = styled.div`
  cursor: pointer;
  height: 2rem;
  margin: 0 0.25rem;
  position: relative;
  width: 4.5rem;

  &::before {
    background: linear-gradient(90deg, rgb(210, 222, 255) 0%, rgb(210, 222, 255) 10%, rgb(235, 240, 255) 64%, rgb(234, 240, 255) 73%, rgb(236, 241, 255) 100%);
    border: 1.5px solid ${color('blue100')};
    border-radius: 45px;
    content: '';
    inset: 0;
    position: absolute;
  }

  ${media.min('xxs')`
    margin: 0 0.5rem;
  `}

  ${media.min('xs')`
    margin: 0 1.5rem;
  `}
`;

/**
 * `SwitchHandle` styled component.
 */

const SwitchHandle = styled.div`
  border-radius: 50%;
  box-shadow: 0 1px 8px 0 rgba(1, 16, 61, 0.28);
  height: 2rem;
  position: relative;
  transform: translateX(0);
  transition: transform ${theme('transitions.default')};
  width: 2rem;

  &::after,
  &::before {
    border-radius: 50%;
    content: '';
    inset: 0;
    position: absolute;
    transition: opacity ${theme('transitions.default')};
  }

  &::before {
    background-image: linear-gradient(-225deg, rgb(189, 202, 240) 0%, rgb(188, 202, 242) 40%, rgb(224, 231, 251) 100%);
    border: 1.5px solid #abb4e4;
    opacity: 1;
  }

  &::after {
    background-image: linear-gradient(-225deg, rgb(109, 237, 218) 0%, rgb(136, 242, 225) 37%, rgb(83, 212, 191) 100%);
    border: 1.5px solid #67c2c1;
    opacity: 0;
  }

  ${ifProp('isChecked', css`
    transform: translateX(2.5rem);

    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
    }
  `)}
`;

/**
 * `Pill` styled component.
 */

const Pill = styled(Type.Label)`
  align-items: center;
  background-color: ${color('green400')};
  border-radius: 16px;
  color: ${color('white')};
  display: flex;
  grid-column: 1 / -1;
  justify-content: center;
  justify-self: end;
  margin-left: 1rem;
  min-height: 2rem;
  opacity: 0;
  padding: 0 0.75rem;
  transition: opacity ${theme('transitions.default')};

  ${ifProp('isVisible', css`
    opacity: 1;
  `)}
`;

/**
 * `BonusSwitch` component.
 */

function BonusSwitch(props: BonusSwitchProps): Node {
  const { mintBonus, switchLeftLabel, switchRightLabel } = props;
  const [mintBonusVisible, setMintBonusVisible] = useState(false);

  return (
    <Wrapper aria-role={'table'}>
      <Content aria-role={'row'}>
        <RawHtml
          aria-role={'rowheader'}
          as={'div'}
          color={color('blue400')}
          element={Type.Paragraph}
          textAlign={'left'}
          textTransform={'uppercase'}
        >
          {switchLeftLabel}
        </RawHtml>

        <VisuallyHidden aria-role={'cell'}>
          {'0%'}
        </VisuallyHidden>
      </Content>

      <SwitchBackground
        aria-hidden
        aria-role={'none'}
        onClick={() => setMintBonusVisible(visible => !visible)}
      >
        <SwitchHandle isChecked={mintBonusVisible} />
      </SwitchBackground>

      <Content aria-role={'row'}>
        <RawHtml
          aria-role={'rowheader'}
          as={'div'}
          color={color('blue400')}
          element={Type.Paragraph}
          textAlign={'right'}
          textTransform={'uppercase'}
        >
          {switchRightLabel}
        </RawHtml>

        <Pill
          aria-role={'cell'}
          as={'div'}
          isVisible={mintBonusVisible}
        >
          {mintBonus}
        </Pill>
      </Content>
    </Wrapper>
  );
}

/**
 * Export `BonusSwitch` component.
 */

export default BonusSwitch;
