// @flow

/**
 * Module dependencies.
 */

import { Container } from 'components/core/layout';
import { type ItemsList } from 'types/items-list';
import { Type } from 'react-components/typography';
import { color } from 'react-components/styles';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import SlidingListWithImage from 'components/core/sliding-list-with-image';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  description: string,
  items: Array<ItemsList>,
  title: string
|};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  color: ${color('blue800')};
  padding-bottom: 5rem;
  padding-top: 7.4rem;
  position: relative;
`;

/**
 * `HowToStart` component.
 */

function HowToStart(props: Props): Node {
  const { description, items, title } = props;

  return (
    <Section>
      <Container>
        <Type.H2
          as={'div'}
          marginBottom={'1.5rem'}
          textAlign={'center'}
        >
          <RawHtml>
            {title}
          </RawHtml>
        </Type.H2>

        <Type.H4
          as={'div'}
          marginBottom={'1.5rem'}
          textAlign={'center'}
        >
          <RawHtml>
            {description}
          </RawHtml>
        </Type.H4>

        <SlidingListWithImage items={items} />
      </Container>
    </Section>
  );
}

/**
 * Export `HowToStart` component.
 */

export default HowToStart;
