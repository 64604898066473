// @flow

/**
 * Module dependencies.
 */

import { Accordion, AccordionItem } from 'components/core/accordion';
import { Container } from 'components/core/layout';
import { Type } from 'react-components/typography';
import { color, media } from 'react-components/styles';
import { useSlider } from 'hooks/use-slider';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  description: ?string,
  items: [{
    description: string,
    id: string,
    title: string
  }],
  title: string
|};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  background-color: ${color('white')};
  color: ${color('blue800')};
  padding: 5rem 0 7.5rem;
  position: relative;
`;

/**
 * `AccordionWrapper` styled component.
 */

const AccordionWrapper = styled.div`
  display: grid;
  grid-template-areas: 'content';

  ${media.min('ms')`
    grid-column-gap: 1.25rem;
    grid-template-areas: '. content .';
    grid-template-columns: 1fr 2fr 1fr;
    justify-content: center;
  `}
`;

/**
 * `ItemText` styled component.
 */

const ItemText = styled(Type.Paragraph).attrs({ as: 'span' })`
  color: ${color('grey700')};
`;

/**
 * `Faqs` component.
 */

function Faqs({ description, items, title }: Props): Node {
  const [activeSlide, setActiveSlide] = useSlider(items.length, null);

  return (
    <Section>
      <Container>
        <Type.H2
          as={'div'}
          textAlign={'center'}
        >
          <RawHtml>
            {title}
          </RawHtml>
        </Type.H2>

        {description && (
          <Type.H4
            as={'div'}
            paddingTop={'1.5rem'}
            textAlign={'center'}
          >
            <RawHtml>
              {description}
            </RawHtml>
          </Type.H4>
        )}

        <AccordionWrapper>
          <Accordion>
            {items.map(({ description, id, title }, index) => (
              <AccordionItem
                active={activeSlide === index}
                key={id}
                setActive={() => setActiveSlide(index)}
                title={title}
              >
                <ItemText>
                  <RawHtml>
                    {description}
                  </RawHtml>
                </ItemText>
              </AccordionItem>
            ))}
          </Accordion>
        </AccordionWrapper>
      </Container>
    </Section>
  );
}

/**
 * Export `Faqs` component.
 */

export default Faqs;
