// @flow

/**
 * Module dependencies.
 */

import { Container } from 'components/core/layout';
import { type FluidImage } from 'types/image';
import { Image } from 'components/core/image';
import { type StoreUrls } from 'types/store-urls';
import { Type } from 'react-components/typography';
import { color, media } from 'react-components/styles';
import Navbar from 'components/navbar';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import SocialNetworks from 'components/social-networks';
import StoreLinks from 'components/join/store-links';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  description: string,
  image: ?FluidImage,
  storeUrls: ?StoreUrls,
  title: string
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.section`
  align-items: center;
  background-color: ${color('blue800')};
  color: ${color('white')};
  display: flex;
  min-height: 801px;
  overflow: hidden;
  position: relative;
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.section`
  display: grid;
  grid-template-rows: 6.5rem min-content 3rem min-content 6.5rem;

  ${media.min('sm')`
    grid-template-rows: 6.5rem min-content 18rem min-content 6.5rem;
  `}

  ${media.min('ms')`
    grid-template-rows: 6.5rem min-content 22.5rem min-content 6.5rem;
  `}

  ${media.min('lg')`
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 12.5rem min-content 7.5rem;
  `}
`;

/**
 * `IllustrationCell` styled component.
 */

const IllustrationCell = styled.div`
  grid-column: 1 / -1;
  grid-row: 2 / span 1;
  padding-top: 56.25%;
  pointer-events: none;
  position: relative;

  ${media.min('sm')`
    grid-row: 2 / -2;
    padding-top: 0;
  `}

  ${media.min('lg')`
    grid-column: 6 / -1;
    grid-row: 1 / -1;
  `}
`;

/**
 * `Illustration` styled component.
 */

const Illustration = styled.div`
  transform: translate(5%, -33%) scale(0.9);

  ${media.max('sm')`
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-30%);
  `}

  ${media.min('lg')`
    bottom: 0;
    left: 0;
    position: absolute;
    transform: translate(-5%, 13%);
    width: 160%;
  `}
`;

/**
 * `ContentCell` styled component.
 */

const ContentCell = styled.div`
  grid-column: 1 / -1;
  grid-row: 4 / span 1;
  position: relative;

  ${media.min('lg')`
    grid-column: span 5;
    grid-row: 2 / span 1;
  `}
`;

/**
 * `StoreLinksWrapper` styled component.
 */

const StoreLinksWrapper = styled.div`
  margin-bottom: 2rem;
  max-width: 37.5rem;
`;

/**
 * `Header` component.
 */

function Header(props: Props): Node {
  const { description, image, storeUrls, title } = props;

  return (
    <Wrapper>
      <Navbar />

      <Container>
        <Grid>
          <IllustrationCell>
            <Illustration>
              <Image fluid={image} />
            </Illustration>
          </IllustrationCell>

          <ContentCell>
            <Type.H1
              as={'div'}
              marginBottom={'2rem'}
            >
              <RawHtml>
                {title}
              </RawHtml>
            </Type.H1>

            <Type.H5
              as={'div'}
              marginBottom={'2rem'}
              maxWidthLg={'33.5rem'}
              maxWidthSm={'37.5rem'}
            >
              <RawHtml>
                {description}
              </RawHtml>
            </Type.H5>

            {storeUrls && (
              <StoreLinksWrapper>
                <StoreLinks urls={storeUrls} />
              </StoreLinksWrapper>
            )}
          </ContentCell>
        </Grid>
      </Container>

      <SocialNetworks />
    </Wrapper>
  );
}

/**
 * Export `Header` component.
 */

export default Header;
